import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>HSBC UK cuts 15 mortgage rates and comments on Budget announcements</h1>
    <p>Following confirmation that HSBC UK will be taking part in the Government’s newly announced Help to Buy scheme, the bank has announced rate cuts of up to 0.1% across its range of LTVs.</p>
    <p>The latest mortgage rate cuts include:</p>
    <ul>
      <li>2 year fixed rate (£999 fee) at 60% LTV down 0.05% to 1.14%.</li>
      <li>5 year fixed rate (fee saver) at 60% LTV down 0.05% to 1.64%.</li>
      <li>2 year fixed rate (fee saver) at 75% LTV cut by 0.10% to 1.79%.</li>
      <li>2 year fixed rate 90% LTV (£999 fee) reduced by 0.10% to 3.14%, with the fee-saver equivalent cut by 0.10% to 3.34%.</li>
    </ul>
    <p>Michelle Andrews, HSBC UK’s Head of Buying a home, commented: “Supporting the UK housing market can take many forms. Last year we continued lending at 90% LTVs during very challenging times, worked hard to work within lockdown rules to continue to value properties in a timely way and continued to on-board broker partners to help give more people access to our products.</p>
    <p>“Now we are cutting mortgage rates again on 15 products across our range of LTVs, and we are also happy to support the Government’s new Help to Buy scheme, which was announced in yesterday’s Budget. This will make a real difference to people moving on and up the housing ladder, and will undoubtedly help realise the home-buying dreams of many.”</p>
    <p>On the extension of the Stamp Duty Land Tax, also announced in the Budget, Michelle Andrews added: “We know the temporary relief of Stamp Duty Land Tax has made a real difference and we welcome the extension. It will not only help those who are in the throes of buying a property and concerned about missing the deadline, it will also help keep momentum in the housing and mortgages markets.”</p>
  </NewsArticle>
)

export default Article
